<!--
 * @Author       : Hugo
 * @Date         : 2021-06-03 10:23:27
 * @LastEditTime : 2021-06-03 13:31:19
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Popper/Avatar.vue
 * @Description  : 
 * @^_^          : 
-->

<template>
  <popper
    class="avatar_page"
    trigger="hover"
    :delay-on-mouse-over="1000"
    :visible-arrow="false"
    :options="{
      placement: 'bottom',
      modifiers: { 
        strategy: 'fixed',
      }
    }">
    <div class="popper">
      Popper Content
    </div>

    <!-- <button slot="reference">
      Reference Element
    </button> -->
    <img 
      slot="reference"
      :src="img_src"
      alt=""
      class="avatar"
    >
    <!-- <div 
      slot="reference"
    class="avatar">111</div> -->
  </popper>
</template>
<script>
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
  components: {
    Popper,
  },
  props: {
    img_src: {
      type: String,
      default: '',
    },
  },
  mounted(){
    // console.log(this.img_src);
  }
}
</script>
<style lang="scss" scoped>
.avatar_page{
  width: 44px;
  height: 44px;
  display: block;
  .popper{
    color:red!important;
  }
  .avatar{
    width: 44px;
    height: 44px;
    border-radius: 4px;
  }
  
 
}

</style>
<style scoped>
/* 隐藏箭头 */
/* .avatar_page >>> .popper__arrow{
  visibility: hidden!important;
}  */
</style>
