var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popper",
    {
      staticClass: "avatar_page",
      attrs: {
        trigger: "hover",
        "delay-on-mouse-over": 1000,
        "visible-arrow": false,
        options: {
          placement: "bottom",
          modifiers: {
            strategy: "fixed",
          },
        },
      },
    },
    [
      _c("div", { staticClass: "popper" }, [
        _vm._v("\n    Popper Content\n  "),
      ]),
      _c("img", {
        staticClass: "avatar",
        attrs: { slot: "reference", src: _vm.img_src, alt: "" },
        slot: "reference",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }