var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.open
        ? [
            _vm._l(160, function (item, index) {
              return [
                _c("avatar", {
                  key: index,
                  attrs: { img_src: `${_vm.$avatar_url}2020/01/03/avatar.png` },
                }),
              ]
            }),
          ]
        : _vm._e(),
      _c("input", { attrs: { type: "text" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }