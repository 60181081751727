<!--
 * @Author       : Hugo
 * @Date         : 2021-06-03 10:28:58
 * @LastEditTime : 2021-06-03 12:41:20
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/views/test/popperjs.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div>
    <template
      v-if="open"
    >
      <template
        v-for="(item,index) in 160"
      >
        <!-- <el-tooltip
          :key="index"
          class="item"
          effect="dark"
          content="Top Center 提示文字"
          placement="top"
        >
          <el-button>上边</el-button>
        </el-tooltip> -->
        <avatar
          :img_src="`${$avatar_url}2020/01/03/avatar.png`"
          :key="index"
        ></avatar>
      </template>
    </template>
    
    

    <input type="text">
  </div>
</template>

<script>
import Avatar from '@/components/Popper/Avatar.vue';
export default {
  components: {
    Avatar,
  },
  data(){
    return {
      open: false,
    }
  },
  mounted(){
    setTimeout(()=>{
      this.open= true;
    },3000)
  }
}
</script>